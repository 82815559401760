<template>
    <sdCards title="Acknowledgement" class="acknowledgement">
        <FormValidationWrap>
            <VerticalFormStyleWrap>
                <a-form name="sDash_validation-form" ref="formRef" :model="formState" :rules="rules"
                    :layout="formState.layout">
                    <a-row :gutter="25">
                        <a-col :span="24" :xs="24">
                            <a-form-item name="customerPoNumber" label="Customer PO number ">
                                <a-input :disabled="readOnlyMode" v-model:value="formState.customerPoNumber" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="24" :xs="24">
                            <a-form-item name="date" label="Date">
                                <a-date-picker :disabled="readOnlyMode" v-model:value="formState.customerPoDate"
                                    :style="{ width: '100%' }" :format="dateFormat" />
                            </a-form-item>
                        </a-col>
                        <a-col :span="24" :xs="24">
                            <a-form-item ref="SaleCustomerPoFile" label="Acknowledgement Files"
                                name="SaleCustomerPoFile">
                                <a-upload v-model:file-list="acknowledgementFiles" name="SaleCustomerPoFile"
                                    :multiple="true" :customRequest="uploadfiles" :style="{ width: '100%' }"
                                    :showUploadList="false">
                                    <a-button v-if="!readOnlyMode" class="btn-outlined" size="large" :outlined="true">
                                        <upload-outlined></upload-outlined>
                                        <span>Select Files</span>
                                    </a-button>
                                </a-upload>
                                <div v-if="acknowledgementFiles.length">
                                    <div v-for="file in acknowledgementFiles" :key="file.uid" class="file-item">
                                        <span class="file-text" :title="file.fileName">{{ file.fileName }}</span>
                                        <a @click="handleDownload(file)">
                                            <sdFeatherIcons type="download" />
                                        </a>
                                        <a @click="removeFile(file)" v-if="!readOnlyMode">
                                            <sdFeatherIcons type="trash-2" />
                                        </a>
                                    </div>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="24" :xs="24" class="acknowledgement-buttons" v-if="!readOnlyMode">
                            <a-button type="primary" @click="validateAcknowledgement">
                                Save Acknowledgement
                            </a-button>
                            <a-button type="default" @click="sendAcknowledgement">
                                Send
                            </a-button>
                        </a-col>
                    </a-row>
                </a-form>
            </VerticalFormStyleWrap>
        </FormValidationWrap>
    </sdCards>
</template>

<script setup>
import { useStore } from "vuex";
import { reactive, ref, computed, defineProps, defineEmits, watchEffect } from "vue";
import { useFileDownload } from '@/composable/useFileDownload';
import {
    FormValidationWrap,
    VerticalFormStyleWrap,
} from "../../view/forms/overview/Style";
import { notification } from "ant-design-vue";

const { state, dispatch } = useStore();
const { downloadFile } = useFileDownload();


const acknowledgementFiles = computed(() => state.FileApi.data);

const props = defineProps(['saleId', 'saleAcknowledgement', 'readOnlyMode', 'saleIsConfirmed']);
const emit = defineEmits(['validate', "send"])

const dateFormat = "YYYY/MM/DD";

const rules = {
    customerPoNumber: [
        {
            required: true,
            message: "Number is required",
            trigger: "change",
        },
    ],
    customerPoDate: [
        {
            type: "object",
            required: true,
            message: "Date is required",
            trigger: "change",
        },
    ],
};
const formRef = ref();
var formState = reactive({
    layout: "vertical",
    saleId: props.saleId,
});


const uploadfiles = (file) => {
    const isPDF = file.file.type === 'application/pdf';
    if (!isPDF) {
        notification.warning({
            message: "Please upload a PDF File",
        });
        return
    }
    var formData = new FormData();
    formData.append("File", file.file);
    if (props.saleId) {
        formData.append("ItemId", props.saleId);
    }
    formData.append("ItemParentType", "Sale");
    formData.append("ItemType", "Sale");

    formData.append("FileType", file.filename);
    formData.append("FileName", file.file.name);
    dispatch("fileSubmitData", {
        formData,
    });
};
const handleDownload = async (file) => {
    // Pass the file ID to initiate download
    await downloadFile(file);
};
const removeFile = (file) => {
    dispatch("removeFile", file);
};

const validateAcknowledgement = () => {
    formState.saleId = props.saleId;
    formRef.value
        .validate()
        .then(async () => {
            emit('validate', formState);
        })
        .catch((error) => {
            console.log("error", error);
        });
}
const sendAcknowledgement = () => {
    formState.saleId = props.saleId;
    formRef.value
        .validate()
        .then(async () => {
            emit('send', formState);
        })
        .catch((error) => {
            console.log("error", error);
        });
}

watchEffect(() => {
    if (props.saleAcknowledgement) {
        formState.customerPoNumber = props.saleAcknowledgement.customerPoNumber;
        formState.customerPoDate = props.saleAcknowledgement.customerPoDate;
    }

});
</script>

<style scoped>
.file-text {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.file-item {
    margin-top: 4px;
    display: flex;
}

.file-item a,
.file-item span {
    margin-left: 8px;
    cursor: pointer;

    &:nth-child(2) {
        color: #1890ff !important;
    }

    &:last-child {
        color: red !important;
    }
}

.acknowledgement-buttons {
    display: flex;
    justify-content: space-between;
}
</style>